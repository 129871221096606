import React, { FC } from "react";
import { components, OptionProps } from "react-select";
// import { Wrapper, Checkbox } from '@/components/atoms';
import Wrapper from "../../Wrapper";

const Option: FC<OptionProps<any, any>> = (props) => (
  <components.Option {...props}>
    <Wrapper width={1} alignItems="center" justifyContent="space-between">
      {props.children}
      {/*{props.isMulti && <Checkbox text="" checked={props.isSelected} readOnly />}*/}
    </Wrapper>
  </components.Option>
);

export default Option;
