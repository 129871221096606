import React, { FC } from "react";
import Select from "../../atoms/Select";
import FieldWrapper from "../FieldWrapper";
import { SelectProps } from "../../atoms/Select";

interface SelectFieldProps extends Pick<SelectProps, "options"> {
  label?: string;
  name: string;
  wrapperStyle?: object;
  info?: string;
  required?: boolean;
  tooltipMessage?: string;
  isMulti?: boolean;
  disabled?: boolean;
  onChange?: (value: any) => void;
  placeholder?: string;
}

// todo: fix select doesnt display initial value
const SelectField: FC<SelectFieldProps> = ({
  label,
  name,
  wrapperStyle,
  info,
  options,
  required = false,
  tooltipMessage,
  isMulti,
  disabled,
  placeholder,
  onChange,
}) => (
  <FieldWrapper
    {...{ label, name, wrapperStyle, info, required, tooltipMessage, options }}
  >
    {(field, { setValue }) => (
      <>
        <Select
          {...{
            setValue,
            options,
            label,
            isMulti,
            disabled,
            placeholder,
            name: field.name,
            value:
              options.filter(({ value }) => value === field.value)[0]?.label ||
              "",
          }}
        />
      </>
    )}
  </FieldWrapper>
);

export default SelectField;
