import { FC, useEffect, useState } from "react";
import { Box } from "reflexbox";
import SelectField from "../../../../molecules/SelectField";
import { useTranslation } from "react-i18next";
import getTranslatedText from "../../../../../utils/getTranslatedText";
import { ShopFormProps } from "./types";

const ShopForm: FC<ShopFormProps> = ({ productOptions, values }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<{ [key: string]: any } | undefined>(
    undefined
  );
  const keys = Object.keys(productOptions);

  useEffect(() => {
    let newOptions = options;
    const keys = Object.keys(productOptions);

    keys.map((key, index) => {
      if (productOptions[key]?.fetch?.endpoint !== undefined) {
        // @ts-ignore
        let endpoint = productOptions[key].fetch.endpoint || " ";
        // @ts-ignore
        const endpointURL = new URL(productOptions[key].fetch.endpoint);
        const params = endpointURL.search
          .slice(1)
          .split("&")
          .map((w) => w.split("=")[0])
          .filter((p) => p);

        if (params.length > 0) {
          params.map((param) => {
            if (values[param])
              endpoint = endpoint.replace(`%${param}`, values[param] || "");
          });
        }
        const emptyParams = params.filter((param) => !values[param]).length > 0;

        if (!emptyParams && endpoint) {
          fetch(endpoint)
            .then((response) => response.json())
            .then((data) => {
              const dataStorage = productOptions[key]?.fetch?.dataStorage;
              if (dataStorage)
                newOptions = { ...newOptions, [dataStorage]: data };

              setOptions(newOptions);
            });
        }
      }
    });
  }, [values]);

  return (
    <div style={{ width: "100%" }}>
      <h3
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "18px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {t("buyItem.productSettings")}
      </h3>
      {keys.map((key, index) => {
        const field = productOptions[key];
        const dataStorage = field?.fetch?.dataStorage;
        const cleanOption =
          options && dataStorage ? options[dataStorage] || [] : [];
        const fieldOptions =
          cleanOption
            .map((option: any) => {
              const fieldParam = field?.param || "";
              if (
                fieldParam &&
                (Object.keys(option).includes(fieldParam) ||
                  fieldParam === "rentalAgeGroup")
              ) {
                const param = typeof option[fieldParam] === "string";
                if (param)
                  return {
                    value: option[fieldParam],
                    label: getTranslatedText(option.name),
                  };
                else if (
                  values["rentalCategory"] === option["rentalCategory"]
                ) {
                  const newParam = option[fieldParam]
                    ? fieldParam
                    : `${fieldParam}s`;

                  return option[newParam]?.map((subOption: any) => ({
                    value: subOption[fieldParam],
                    label: getTranslatedText(subOption?.name),
                  }));
                } else return undefined;
              } else return undefined;
            })
            .filter((v: any) => v) || [];

        const fixedFieldOptions = Array.isArray(fieldOptions[0])
          ? [].concat(...fieldOptions)
          : fieldOptions;

        return (
          <Box width={1} key={key + index}>
            {field?.type === "select" && (
              <SelectField
                label={getTranslatedText(field?.label)}
                name={key}
                options={fixedFieldOptions}
                disabled={fixedFieldOptions.length == 0}
              />
            )}
          </Box>
        );
      })}
    </div>
  );
};

export default ShopForm;
