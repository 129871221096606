// @ts-nocheck
import { FC, useContext, useEffect, useState } from "react";
import BookingCalendarWithStatuses from "../../../molecules/BookingCalendarWithStatuses";
import ShopForm from "./ShopForm";
import styled from "@emotion/styled";
import { Flex } from "reflexbox";
import { Formik, Form } from "formik";
import { getInitialValues } from "./../functions";
import { validationSchema } from "./validationSchema";
import PersonalisationForm from "./PersonalisationForm";
import { getMembers, postAddToCart } from "../../../../api/endpoints";
import { toast } from "react-toastify";
import Summary from "./Summary";
import MemberList from "./MemberList";
import { AppContext } from "../../../../AppContext";
import { Member, PriceObjectType } from "./types";
import { useTranslation } from "react-i18next";

const SectionContainer = styled(Flex)`
  background-color: white;
  padding: 16px;
  justify-content: center;
`;
SectionContainer.defaultProps = {
  width: [1, 1, "49%", "24%"],
  mb: "1%",
};
const BuyItemForm: FC<{ product: any }> = ({ product }) => {
  const { t } = useTranslation();
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;
  const [priceObject, setPriceObject] = useState<PriceObjectType | undefined>(
    undefined
  );
  const [selectedMember, setSelectedMember] = useState<Member | undefined>(
    undefined
  );
  const [showMemberList, setShowMemberList] = useState<boolean>(true);
  const [members, setMembers] = useState<Member[]>([]);
  const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
  const [lockAddToCart, setLockAddToCart] = useState<boolean>(false);
  const [cartId, setCartId] = useState<string | null>(
    sessionStorage.getItem("cartId")
  );

  const showCalendar = product.productOptions.date;
  const NumberOfSection =
    [
      Boolean(product.productOptions),
      showCalendar,
      Boolean(product.personalisation),
    ].filter((v) => v).length + 1;

  const fetchMembers = () => {
    setLoadingMembers(true);
    getMembers().then((res) => {
      setLoadingMembers(false);
      res.ok && setMembers(res.data as Member[]);
    });
  };

  const addToCart = (values, { resetForm }) => {
    const personalisation = Object.keys(values)
      .filter((key) => Object.keys(product?.personalisation).includes(key))
      .reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});
    priceObject &&
      !lockAddToCart &&
      postAddToCart({
        query: {
          cartId: cartId?.length ? cartId : undefined,
          category: values.rentalCategory,
          details: "",
          endDate: values.endDate,
          personalisation: JSON.stringify(personalisation),
          groupMemberId: selectedMember?.id,
          price: priceObject?.totalPrice,
          sku: priceObject.sku,
          startDate: values.startDate,
          token,
        },
      }).then((res: any) => {
        if (res.ok) {
          sessionStorage.setItem("cartId", String(res.data.cartId));
          setCartId(res.data.cartId);
          toast("Added to cart", { type: "success" });
          resetForm();
          setPriceObject(undefined);
          setSelectedMember(undefined);
          setShowMemberList(true);
          fetchMembers();
          setLockAddToCart(false);
        } else {
          toast("Not added to cart", { type: "error" });
          setLockAddToCart(false);
        }
      });
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <Formik<any>
      initialValues={getInitialValues(product)}
      onSubmit={addToCart}
      validationSchema={() => validationSchema(product, t)}
    >
      {({ values, setFieldValue, handleSubmit, errors, submitCount }) => (
        <Form>
          <Flex flexWrap={"wrap"}>
            {showCalendar && (
              <SectionContainer
                mr={[0, 0, "1%", "1%"]}
                width={[1, 1, "49.5%", 100 / NumberOfSection - 1 + "%"]}
              >
                <BookingCalendarWithStatuses
                  {...{
                    startDate: values?.startDate,
                    setStartDate: (newDate: any) =>
                      setFieldValue("startDate", newDate),
                    endDate: values?.endDate,
                    setEndDate: (newDate: any) =>
                      setFieldValue("endDate", newDate),
                  }}
                />
              </SectionContainer>
            )}
            {product?.productOptions && (
              <SectionContainer
                mr={[0, 0, 0, "1%"]}
                width={[1, 1, "49.5%", 100 / NumberOfSection - 1 + "%"]}
              >
                <ShopForm
                  productOptions={product.productOptions}
                  values={values}
                />
              </SectionContainer>
            )}
            {product?.personalisation && (
              <SectionContainer
                mr={[0, 0, "1%", "1%"]}
                width={[1, 1, "49.5%", 100 / NumberOfSection - 1 + "%"]}
              >
                {showMemberList ? (
                  <MemberList
                    members={members}
                    selectedMember={selectedMember}
                    setFieldValue={setFieldValue}
                    setSelectedMember={setSelectedMember}
                    goNext={() => setShowMemberList(false)}
                    personalisation={product.personalisation}
                    errors={errors}
                    submitCount={submitCount}
                    fetchMembers={fetchMembers}
                    loadingMembers={loadingMembers}
                  />
                ) : (
                  <PersonalisationForm
                    personalisation={product.personalisation}
                    setFieldValue={setFieldValue}
                    values={values}
                    goBack={() => setShowMemberList(true)}
                  />
                )}
              </SectionContainer>
            )}
            <SectionContainer
              width={[
                1,
                1,
                NumberOfSection % 2 !== 0 ? 1 : "49.5%",
                100 / NumberOfSection + "%",
              ]}
              alignItems={"flex-start"}
            >
              <Summary
                productOptions={product.productOptions}
                handleSubmit={handleSubmit}
                priceObject={priceObject}
                setPriceObject={setPriceObject}
                values={values}
                errors={errors}
                submitCount={submitCount}
              />
            </SectionContainer>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default BuyItemForm;
