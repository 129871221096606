import styled from "@emotion/styled";
import { Box, Flex } from "reflexbox";

export const ButtonContainer = styled(Flex)`
  padding-top: 20px;
  width: 100%;
  justify-content: center;
`;

export const BackContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;
export const InfoContainer = styled(Box)`
  width: 100%;
  text-align: center;
  background: white;
  padding: 50px 0;
  margin-bottom: 2%;
`;
export const PriceContainer = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  background: white;
  padding: 16px;
`;
PriceContainer.defaultProps = {
  width: [1, 1, 1, "26%"],
};
export const PriceAndButtons = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  padding-top: 20px;
`;
