import React, { FC } from "react";
import { FaAngleDown } from "react-icons/fa";

const IndicatorsContainer: FC<{}> = () => (
  <FaAngleDown
    color={"white"}
    size={30}
    style={{ padding: "9px 10px", backgroundColor: "black" }}
  />
);

export default IndicatorsContainer;
