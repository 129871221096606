import { FC, useContext, useEffect, useState } from "react";
import { fetchProductOptions } from "../../../../api/endpoints";
import { AppContext } from "../../../../AppContext";

const CartWrapper: FC = ({ children }) => {
  const { productIdState } = useContext(AppContext);
  const [product, setProduct] = useState(undefined);
  const [productId] = productIdState;

  useEffect(() => {
    fetchProductOptions(productId).then((res: any) => {
      if (res.ok) setProduct(res.data || undefined);
    });
  }, [productId]);

  // @ts-ignore
  return <>{children && children(product)}</>;
};

export default CartWrapper;
