import { string, object } from "yup";
import { ProductType } from "../type";
import { TFunction } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validationSchema = (
  product: ProductType,
  t: TFunction
): object => {
  const keysProductOptions = product.productOptions
    ? Object.keys(product.productOptions)
    : [];
  const keysPersonalisation = product.personalisation
    ? Object.keys(product.personalisation)
    : [];
  const shapeProductOption = keysProductOptions.reduce((obj, item) => {
    if (item === "date") {
      return {};
    } else if (product?.productOptions)
      return Object.assign(obj, {
        [item]: product.productOptions[item]?.options?.required
          ? string()
              .nullable()
              .required(() => t("commons.fieldRequired"))
          : string().nullable(),
      });
    else return {};
  }, {});
  const shapePersonalisation = keysPersonalisation.reduce((obj, item) => {
    if (item === "date") {
      return {};
    } else if (product?.personalisation)
      return Object.assign(obj, {
        [item]: product.personalisation[item]?.validation?.required
          ? string()
              .nullable()
              .required(() => t("commons.fieldRequired"))
          : string().nullable(),
      });
    else return {};
  }, {});

  return object().shape({ ...shapeProductOption, ...shapePersonalisation });
};
