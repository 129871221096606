import { FC, useContext, useEffect, useState } from "react";
import FlimsLaaxLogo from "../../atoms/FlimsLaaxLogo/FlimsLaaxLogo";
import flimsLaaxLogo from "../../../assets/flimsLaaxLogo.png";
import "./styled.css";
import { AppContext } from "../../../AppContext";
import { getUserInfo } from "../../../api/endpoints";

const BookTicketStatusBar: FC<any> = ({ title }) => {
  const { tokenState } = useContext(AppContext);
  const [token, setToken] = tokenState;
  const [userInfo, setUserInfo] = useState<any>(undefined);
  const [userInfoLoading, setUserInfoLoading] = useState<any>(false);

  useEffect(() => {
    setUserInfoLoading(true);
    getUserInfo().then((res) => {
      res.ok && setUserInfo(res.data);
      setUserInfoLoading(false);
    });
  }, [token]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "right",
        alignItems: "center",
        backgroundColor: "white",
        width: "100%",
        height: "200px",
        position: "relative",
      }}
    >
      {token && !userInfoLoading && (
        <div style={{ position: "absolute", left: 10, top: 10 }}>
          <div style={{ fontWeight: "bold" }}>{userInfo?.email}</div>
          <div
            style={{
              cursor: "pointer",
              width: "max-content",
            }}
            onClick={() => {
              setToken("");
              localStorage.setItem("token", "");
            }}
          >
            Log out
          </div>
        </div>
      )}
      <div
        style={{
          width: "calc(90% - 120px)",
          textAlign: "center",
        }}
      >
        <h2>{title}</h2>
      </div>
      <div
        style={{
          width: "120px",
          textAlign: "center",
        }}
      >
        <FlimsLaaxLogo alt="Flims Laax Logo" src={flimsLaaxLogo} />
      </div>
    </div>
  );
};

export default BookTicketStatusBar;
