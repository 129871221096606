import { FC } from "react";
import formatPriceForDisplay from "../../../utils/formatPriceForDisplay";

const Price: FC<any> = ({ price, variant }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        color: variant === "small" ? " black" : "#b30019",
        fontFamily: "Helvetica Neue LT W05_85 Heavy,sans-serif",
        fontWeight: 400,
      }}
    >
      <div
        style={{
          fontSize: variant === "small" ? "16px" : "18px",
          paddingBottom: variant === "small" ? "1px" : "4px",
          paddingRight: "10px",
        }}
      >
        CHF
      </div>
      <div
        style={{
          fontSize: variant === "small" ? "30px" : "42px",
          lineHeight: 1,
        }}
      >
        {formatPriceForDisplay(price)}
      </div>
    </div>
  );
};

export default Price;
