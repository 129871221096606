import React, { ReactElement, useEffect } from "react";
import { useField, FieldInputProps, FieldHelperProps } from "formik";
import { Box, Flex } from "reflexbox";
import Label from "../../atoms/Label";

export type FieldType = string | string[] | number | boolean | Date;

interface FieldWrapperProps<T> {
  label?: string;
  name: string;
  children(
    field: FieldInputProps<T>,
    helpers: FieldHelperProps<T>
  ): ReactElement;
  onChange?: (value: T) => void;
  required: boolean;
  wrapperStyle?: any;
  options?: { label: string; value: any }[];
}

function FieldWrapper<T extends FieldType>({
  children,
  label,
  name,
  onChange,
  required,
  wrapperStyle,
  options,
}: FieldWrapperProps<T>): ReactElement {
  const [field, meta, helpers] = useField<T>(name);
  const showError = meta.error && meta.touched;
  const requiredSign = required ? "*" : "";

  useEffect(() => {
    if (onChange && field.value) onChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  useEffect(() => {
    // @ts-ignore
    if (
      field.value &&
      options &&
      options.filter((o) => o.value === field.value).length === 0
    ) {
      // @ts-ignore
      helpers.setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Box width={1} {...wrapperStyle}>
      {label !== undefined && <Label text={label} />}
      <Box width={1}>{children && children(field, helpers)}</Box>
      <Flex height={"10px"} alignItems={"flex-start"} pt={"2px"}>
        {showError && <span style={{ color: "red" }}>{meta.error}</span>}
      </Flex>
    </Box>
  );
}

export default FieldWrapper;
