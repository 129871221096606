import { FC, useEffect, useState } from "react";
import { getDayOption, monthOptions, yearOptions } from "./utils";
import { Box } from "reflexbox";
import Select from "../../atoms/Select";
import moment from "moment";

const DateSelect: FC<any> = ({ value, setDate, disabled }) => {
  const [yearBirth, setYearBirth] = useState(
    value ? moment(value).format("YYYY") : ""
  );
  const [oldValue, setOldValue] = useState(undefined);
  const [monthBirth, setMonthBirth] = useState(
    value ? moment(value).format("M") : ""
  );
  const [dayBirth, setDayBirth] = useState(
    value ? moment(value).format("D") : ""
  );
  const dateFormat = (date: Date) => moment(date).format("YYYY-MM-DD");

  const dayOptions =
    monthBirth && yearBirth ? getDayOption(monthBirth, yearBirth) : [];

  const YearSelect = (
    <Box pb={2}>
      <Select
        value={yearBirth}
        name={"yearBirth"}
        options={yearOptions}
        placeholder={"YYYY"}
        setValue={setYearBirth}
        disabled={disabled}
      />
    </Box>
  );
  const DaySelect = (
    <Box pb={2}>
      <Select
        value={dayBirth}
        name={"dayBirth"}
        disabled={disabled || !monthBirth}
        options={dayOptions}
        placeholder={"DD"}
        setValue={setDayBirth}
      />
    </Box>
  );

  useEffect(() => {
    if (dayBirth && monthBirth && yearBirth) {
      setDate(
        dateFormat(
          new Date(Number(yearBirth), Number(monthBirth), Number(dayBirth))
        )
      );
    } else setDate("");
  }, [dayBirth, monthBirth, yearBirth]);

  useEffect(() => {
    if (!value && oldValue) {
      setMonthBirth("");
      setDayBirth("");
      setYearBirth("");
      setOldValue(value);
    } else {
      setOldValue(value);
    }
  }, [value]);

  return (
    <Box>
      {false ? DaySelect : YearSelect}
      <Box pb={2}>
        <Select
          value={monthBirth}
          name={"monthBirth"}
          options={monthOptions}
          placeholder={"MM"}
          setValue={setMonthBirth}
          disabled={disabled || !yearBirth}
        />
      </Box>
      {false ? YearSelect : DaySelect}
    </Box>
  );
};

export default DateSelect;
