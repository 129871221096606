import moment from "moment";
import { FC } from "react";
import { FaSkiing, FaSnowboarding } from "react-icons/fa";
import Price from "../../atoms/Pice";
import CloseRed from "../../../icons/closeRed.svg";
import { Box, Flex } from "reflexbox";
import styled from "@emotion/styled";
import i18next from "i18next";
import getTranslatedText from "../../../utils/getTranslatedText";

const Container = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-bottom: 16px;
  border-bottom: 2px solid #f0f0f0;
  position: relative;
`;
const CloseButton = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
const CartItem: FC<any> = ({
  name,
  category,
  startDate,
  endDate,
  price,
  onDeleteClick,
  personalisation,
  productPersonalisation,
  id,
}) => {
  const showEnglish = i18next.language === "en";
  const objectPersonalisation = personalisation
    ? JSON.parse(personalisation)
    : {};
  const getIcon = () =>
    category == "snowboard" ? (
      <FaSnowboarding size={70} color={"#b30019"} />
    ) : (
      <FaSkiing size={70} color={"#b30019"} />
    );
  return (
    <Container width={1} flexWrap={"wrap"} p={"16px"} bg={"white"} key={id}>
      <Flex
        alignItems={"center"}
        width={[1, 1, 3 / 5, 2 / 3]}
        pr={["40px", "40px", 0]}
      >
        <Box display={["none", "none", "flex"]}>{getIcon()}</Box>
        <Box pl={[0, 0, "20px"]}>
          <h4 style={{ margin: 0 }}>{name}</h4>
          <div style={{ color: "grey" }}>
            {moment(startDate).format("D-MM") +
              " - " +
              moment(endDate).format("D-MM") +
              " (" +
              Number(moment(endDate).diff(startDate, "days") + 1) +
              " day)"}
          </div>
          <Flex flexWrap={"wrap"} pt={2} color={"grey"}>
            {objectPersonalisation["firstname"] && (
              <Box width={[1, 1 / 2, 1 / 2]}>
                {getTranslatedText(productPersonalisation["firstname"]?.label)}
                {": " + objectPersonalisation["firstname"]}
              </Box>
            )}
            {objectPersonalisation["lastname"] && (
              <Box width={[1, 1 / 2, 1 / 2]}>
                {getTranslatedText(productPersonalisation["lastname"]?.label)}
                {": " + objectPersonalisation["lastname"]}
              </Box>
            )}
            {Object.keys(objectPersonalisation)
              .filter((key) => key !== "firstname" && key !== "lastname")
              .map((key) => (
                <Box width={[1, 1 / 2, 1 / 2]} key={key}>
                  {getTranslatedText(productPersonalisation[key]?.label)}:{" "}
                  {productPersonalisation[key]?.type === "select"
                    ? getTranslatedText(
                        productPersonalisation[key].values[
                          objectPersonalisation[key]
                        ].label
                      )
                    : objectPersonalisation[key]}
                </Box>
              ))}
          </Flex>
        </Box>
      </Flex>
      <Flex
        justifyContent={["flex-end", "space-between", "flex-end"]}
        width={[1, 1, 2 / 5, 1 / 3]}
        pr={[0, 0, "40px"]}
        pt={[2, 2, 0]}
      >
        <Box display={["none", "flex", "none"]} width={"50px"}>
          {getIcon()}
        </Box>
        <Price variant={"small"} price={price} />
      </Flex>
      <CloseButton>
        <img
          alt="close-btn"
          src={CloseRed}
          style={{ marginLeft: "40px", cursor: "pointer" }}
          onClick={onDeleteClick}
        />
      </CloseButton>
    </Container>
  );
};

export default CartItem;
