import { ProductType } from "./type";
import moment from "moment";

export const getInitialValues = (product: ProductType) => {
  const keysProductOptions = product?.productOptions
    ? Object.keys(product.productOptions)
    : [];
  const keysPersonalisation = product?.personalisation
    ? Object.keys(product.personalisation)
    : [];
  const initialProductOptions = keysProductOptions.reduce((obj, item) => {
    if (item === "date") {
      return {
        ...obj,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      };
    } else if (product.productOptions)
      return {
        ...obj,
        [item]: product.productOptions[item].options.defaultValue || "",
      };
    else return {};
  }, {});
  const initialPersonalisation = keysPersonalisation.reduce((obj, item) => {
    if (item === "date") {
      return {
        ...obj,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      };
    } else if (product.personalisation)
      return {
        ...obj,
        [item]: "",
      };
    else return {};
  }, {});

  return { ...initialProductOptions, ...initialPersonalisation };
};
