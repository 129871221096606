import { FC, useEffect } from "react";
import MainButton from "../../../../atoms/MainButton";
import Price from "../../../../atoms/Pice";
import LoginButton from "../../../../molecules/LoginButton/LoginButton";
import { Flex } from "reflexbox";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { SummaryProps } from "./types";

const Summary: FC<SummaryProps> = ({
  productOptions,
  values,
  handleSubmit,
  priceObject,
  setPriceObject,
  submitCount,
  errors,
}) => {
  const { t } = useTranslation();
  const canAddToCart =
    priceObject?.sku && (submitCount === 0 || Object.keys(errors).length === 0);
  const goToCartHref =
    i18next.language === "en"
      ? `${window.location.origin}/cart?lang=en`
      : `${window.location.origin}/cart`;

  useEffect(() => {
    const productOptionsKeys = Object.keys(productOptions) || [];
    productOptionsKeys.map((productOptionsKey) => {
      const endpoint =
        productOptions[productOptionsKey]?.behavior?.onchange?.endpoint;
      if (endpoint) {
        let newEndpoint = endpoint;
        const endpointURL = new URL(endpoint);
        const params = endpointURL.search
          .slice(1)
          .split("&")
          .map((w) => w.split("=")[0])
          .filter((p) => p);

        if (params.length > 0) {
          params.map((param) => {
            if (values[param]) {
              const newParam =
                param === "startDate" || param === "endDate"
                  ? moment(values[param]).format("YYYY-MM-DD")
                  : values[param] || "";

              newEndpoint = newEndpoint.replace(`%${param}`, newParam);
            }
          });
        }
        const emptyParams = params.filter((param) => !values[param]).length > 0;

        if (!emptyParams && newEndpoint) {
          fetch(newEndpoint, {
            method: "POST",
          })
            .then((response) => response.json())
            .then((data) => {
              setPriceObject(data[0]);
            })
            .catch(() => console.log("error"));
        }
      }
    });
  }, [values]);

  return (
    <Flex flexWrap={"wrap"}>
      <Flex width={1} justifyContent={"center"}>
        <h3
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "18px",
          }}
        >
          {t("commons.price")}
        </h3>
      </Flex>
      <Flex width={1} justifyContent={"center"} flexWrap={"wrap"}>
        <Flex p={"20px 0"} width={1} justifyContent={"center"}>
          <Price price={priceObject?.totalPrice || 0} />
        </Flex>
        <Flex width={1} justifyContent={"center"}>
          <LoginButton authenticatedFunction={canAddToCart && handleSubmit}>
            <MainButton disabled={!canAddToCart}>
              {t("buyItem.addToCart")}
            </MainButton>
          </LoginButton>
        </Flex>
        <Flex paddingTop={"10px"} width={1} justifyContent={"center"}>
          <LoginButton
            authenticatedFunction={() => {
              window.location.href = goToCartHref;
            }}
          >
            <MainButton>{t("buyItem.goToCart")}</MainButton>
          </LoginButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Summary;
