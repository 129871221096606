import styled from "@emotion/styled";
import { StylesConfig } from "react-select";

export const customStyles: StylesConfig = {
  // @ts-ignore
  container: (provided, { selectProps: { height } }) => ({
    ...provided,
    width: "100%",
    height: height === "small" ? 40 : 48,
  }),
  // @ts-ignore
  control: (provided, { isFocused, selectProps: { height, isDisabled } }) => ({
    ...provided,
    border: "none",
    backgroundColor: `rgb(240, 240, 240)`,
    height: height === "small" ? 40 : 48,
    opacity: isDisabled ? 0.5 : 1,
    cursor: "pointer",
  }),

  option: (provided, { isMulti, isSelected }) => ({
    ...provided,
    fontSize: "14px",
    color: isSelected && !isMulti ? "black" : "black",
    backgroundColor: "white",
    cursor: "pointer",
  }),
  placeholder: () => ({
    color: "black",
    position: "absolute",
    top: "4px",
    fontSize: "18px",
    paddingLeft: "10px",
  }),
};

export const Img = styled.img`
  padding-right: 12px;
`;

export const MultiCountLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  margin-right: 14px;
`;

export const SingleValueLabel = styled.span<{ boldSelectedValue?: boolean }>`
  position: absolute;
  top: 4px;
  font-size: 18px;
  color: ${({ boldSelectedValue }) => (boldSelectedValue ? "black" : "black")};
  padding-left: 10px;
`;

export const MultiCountLabel = styled.span`
  font-size: 14px;
  color: black;
`;
