import { FC } from "react";
import { Box } from "reflexbox";
import SelectField from "../../../../molecules/SelectField";
import InputField from "../../../../molecules/InputField";
import DateSelectField from "../../../../molecules/DateSelectField";
import { FaAngleLeft } from "react-icons/fa";
import { Container, ArrowContainer } from "./styled";
import getTranslatedText from "../../../../../utils/getTranslatedText";
import { PersonalisationFormProps } from "./types";
import { useTranslation } from "react-i18next";

const PersonalisationForm: FC<PersonalisationFormProps> = ({
  personalisation,
  goBack,
}) => {
  const personalisationKeys = Object.keys(personalisation);
  const { t } = useTranslation();

  return (
    <Container>
      <ArrowContainer>
        <FaAngleLeft size={26} onClick={goBack} />
      </ArrowContainer>
      <h3
        style={{
          paddingLeft: "16px",
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "18px",
        }}
      >
        {t("buyItem.personalSettings")}
      </h3>
      {personalisationKeys.map((key, index) => {
        const field = personalisation[key];
        const getOptions = () => {
          const optionKeys = field.values ? Object.keys(field.values) : [];

          return optionKeys.map((key) => ({
            value: key,
            label: field.values
              ? getTranslatedText(field.values[key]?.label)
              : "",
          }));
        };
        const options = field?.type === "select" ? getOptions() : [];
        return (
          <Box width={1} key={key + index}>
            {field?.type === "input" ? (
              <InputField
                label={getTranslatedText(field?.label)}
                name={key}
                disabled={key === "firstname" || key === "lastname"}
                type={field.value === "int" ? "number" : "string"}
              />
            ) : field?.type === "date" ? (
              <DateSelectField
                disabled={key === "birthdate"}
                label={getTranslatedText(field?.label)}
                name={key}
              />
            ) : (
              <SelectField
                label={getTranslatedText(field?.label)}
                name={key}
                options={options}
              />
            )}
          </Box>
        );
      })}
    </Container>
  );
};

export default PersonalisationForm;
