import { FC, useContext } from "react";
import { lock } from "../../../utils/loginRelated";
import { toast } from "react-toastify";
import { AppContext } from "../../../AppContext";

const LoginButton: FC<any> = ({
  disabled,
  authenticatedFunction,
  children,
}) => {
  const { tokenState } = useContext(AppContext);
  const [token, setToken] = tokenState;
  const checkError = (error: any) => {
    const errDesc = error?.error_description;
    if (error) {
      if (errDesc !== "Login required" && error?.statusCode === 429) {
        toast("error 429", { type: "error" });
      } else if (errDesc && errDesc !== "Login required") {
        toast(errDesc, { type: "error" });
      }
      console.log(error);
    }
  };

  const handleAuthorizeClick = () => {
    if (!token) {
      lock.checkSession({}, function (error, authResult) {
        setTimeout(() => {
          if (!authResult || !token) {
            lock.show();
            lock.on("hide", function () {
              lock.hide();
            });
            lock.on("authenticated", function (authResult) {
              if (authResult && authResult.accessToken) {
                setToken(authResult.accessToken);
                localStorage.setItem("token", authResult.accessToken);
                authenticatedFunction();
              }
            });
          } else if (error) {
            error && checkError(error);
          } else {
            authenticatedFunction();
          }
        }, 1000);
      });
    } else authenticatedFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  return (
    <div onClick={!disabled ? handleAuthorizeClick : undefined}>{children}</div>
  );
};

export default LoginButton;
