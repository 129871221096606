import React, { FC } from "react";
// import { SingleValueProps } from 'react-select';
import { SingleValueLabel } from "../styled";

// @ts-ignore
const SingleValue: FC = ({ children, selectProps: { boldSelectedValue } }) => (
  <SingleValueLabel {...{ boldSelectedValue }}>{children}</SingleValueLabel>
);

export default SingleValue;
