import { FC } from "react";
import "./FlimsLaaxLogo.css";

export interface IFlimsLaaxLogo {
  alt?: string;
  src: string;
}

const FlimsLaaxLogo: FC<IFlimsLaaxLogo> = ({ alt, src }) => {
  return (
    <a
      className={"flims-laax-logo-link"}
      rel="noreferrer"
      target="_blank"
      href="https://www.flimslaax.com/inside-laax-app"
    >
      <img alt={alt} className="flims-laax-logo" src={src}></img>
      <span className="companyName">Flims Laax Falera</span>{" "}
    </a>
  );
};

export default FlimsLaaxLogo;
