import { FC } from "react";
import Calendar from "react-calendar";
import "./BookingCalendar.css";
import "../../../index.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { IBookingCalendar } from "./types";

const BookingCalendar: FC<IBookingCalendar> = ({
  checkIfTileDisabled,
  onChange,
  value,
  renderTileContent,
  defaultActiveStartDate = new Date(),
  lang,
}) => (
  <Calendar
    locale={lang || "de"}
    minDetail="month"
    next2Label={null}
    nextLabel={<FaArrowRight />}
    onChange={onChange}
    prevLabel={<FaArrowLeft />}
    prev2Label={null}
    value={value}
    tileContent={renderTileContent}
    tileDisabled={checkIfTileDisabled}
    defaultActiveStartDate={defaultActiveStartDate}
    className="calendarContainer"
  />
);

export default BookingCalendar;
