import React, { FC } from "react";
import { MultiValueProps, SingleValueProps } from "react-select";
import Wrapper from "../../Wrapper";
import { MultiCountLabel, MultiCountLabelContainer } from "../styled";
import SingleValue from "./SingleValue";

const MultiValue: FC<
  MultiValueProps<any> & SingleValueProps<any> & { index: number }
> = (props) => {
  const {
    getValue,
    index,
    // @ts-ignore
    selectProps: { label },
  } = props;
  const selectedItemsCount = getValue().length;

  if (selectedItemsCount === 1) {
    return <SingleValue {...props}>{props.children}</SingleValue>;
  }

  return !index ? (
    <SingleValue {...props}>
      <Wrapper width={1} backgroundColor={"white"} alignItems="center">
        <MultiCountLabelContainer>
          <MultiCountLabel>{selectedItemsCount}</MultiCountLabel>
        </MultiCountLabelContainer>
        {label}
      </Wrapper>
    </SingleValue>
  ) : null;
};

export default MultiValue;
