import { FC, useContext, useEffect, useState } from "react";
import { fetchProductOptions } from "../../../api/endpoints";
import BookTicketStatusBar from "../../molecules/BookTicketStatusBar";
import BuyItemForm from "./BuyItemForm";
import { AppContext } from "../../../AppContext";
import { Box, Flex } from "reflexbox";
import MainButton from "../../atoms/MainButton";
import LoginButton from "../../molecules/LoginButton/LoginButton";
import { useTranslation } from "react-i18next";

const BuyItem: FC = () => {
  const { tokenState, productIdState } = useContext(AppContext);
  const [token] = tokenState;
  const [productId, setProductId] = productIdState;
  const [product, setProduct] = useState(undefined);
  const { t } = useTranslation();

  const productIdFromQuery =
    new URL(window.location.href).searchParams.get("productId") || "";

  useEffect(() => {
    fetchProductOptions(productId).then((res: any) => {
      if (res.ok) setProduct(res.data || undefined);
    });
  }, [productId]);

  useEffect(() => {
    if (productIdFromQuery) {
      setProductId(productIdFromQuery);
      localStorage.setItem("productId", productIdFromQuery);
    }
  }, [productIdFromQuery]);

  return (
    <Box>
      <Box pb={"20px"}>
        <BookTicketStatusBar title={t("commons.rentalSettings")} />
      </Box>
      {token ? (
        <>{product && <BuyItemForm product={product} />}</>
      ) : (
        <Flex width={1} bg={"white"} justifyContent={"center"} p={"50px"}>
          <LoginButton>
            <MainButton>{t("commons.logIn")}</MainButton>
          </LoginButton>
        </Flex>
      )}
    </Box>
  );
};

export default BuyItem;
