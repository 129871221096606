import styled from "@emotion/styled";
import { Flex, Box } from "reflexbox";

export const MembersContainer = styled(Box)`
  width: 100%;
  position: relative;
`;
export const MembersList = styled(Box)`
  width: 100%;
  height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export const MemberContainer = styled(Flex)`
  padding: 10px 4px;
  border-bottom: 2px solid rgb(240, 240, 240);
  justify-content: space-between;
  align-items: center;
`;
export const Name = styled(Box)`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 4px;
`;
export const GreyText = styled(Box)`
  font-size: 16px;
  color: grey;
`;
export const Error = styled(Box)`
  font-size: 16px;
  color: red;
`;
export const Square = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid black;
  cursor: pointer;
  margin-right: 8px;
`;

export const ArrowContainer = styled(Box)`
  position: absolute;
  top: 16px;
  right: 0;
  cursor: pointer;
`;
export const Delete = styled(Box)`
  transform: rotate(45deg);
  font-size: 40px;
  padding-bottom: 6px;
  line-height: 0;
  cursor: pointer;
  color: grey;
`;
