import React, { FC, useRef } from "react";
import Select, { Props } from "react-select";
import Wrapper from "../Wrapper";
import { customStyles } from "./styled";
import components from "./components";

export interface SelectProps extends Omit<Props, "isMulti" | "options"> {
  setValue: (value: any) => void;
  label?: string;
  variant?: "simple" | "outlined";
  boldSelectedValue?: boolean;
  isMulti?: boolean;
  height?: "small" | "big";
  options: { label: string; value: any }[];
  disabled?: boolean;
  name: string;
}

const SelectWrapper: FC<SelectProps> = ({
  value,
  options,
  placeholder = "",
  setValue,
  label,
  isMulti = false,
  variant = "outlined",
  boldSelectedValue = false,
  height = "big",
  disabled = false,
  name,
}) => {
  return (
    <Wrapper width={1}>
      {/* TODO fix typing
    // @ts-ignore */}
      <Select
        // @ts-ignore
        {...{
          name,
          options,
          placeholder: value,
          components,
          label,
          isMulti,
          variant,
          boldSelectedValue,
          height,
          isDisabled: disabled,
        }}
        value={value ? undefined : ""}
        closeMenuOnSelect={!isMulti}
        styles={customStyles}
        hideSelectedOptions={false}
        isSearchable={false}
        onChange={(option) => {
          // @ts-ignore
          option && setValue(isMulti ? option : option.value);
        }}
      />
    </Wrapper>
  );
};

export default SelectWrapper;
