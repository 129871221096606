import React, { FC } from "react";
import Input from "../../atoms/Input";
import FieldWrapper from "../FieldWrapper";

interface InputFieldProps {
  type?: string;
  label?: string;
  name: string;
  wrapperStyle?: object;
  info?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  leftIcon?: string;
  rightIcon?: string;
  onRightIconClick?: () => void;
  tooltipMessage?: string;
  inputBackgroundTransparent?: boolean;
}

const InputField: FC<InputFieldProps> = ({
  type = "text",
  label,
  name,
  wrapperStyle,
  info,
  disabled,
  required = false,
  placeholder,
  leftIcon,
  rightIcon,
  onRightIconClick,
  tooltipMessage,
  inputBackgroundTransparent,
}) => (
  <FieldWrapper<string>
    {...{
      label,
      name,
      wrapperStyle,
      info,
      disabled,
      required,
      tooltipMessage,
      inputBackgroundTransparent,
    }}
  >
    {/* TODO fix typing
    // @ts-ignore */}
    {(field, helpers, error) => (
      <Input
        {...{
          type,
          disabled,
          placeholder,
          includeIcon: !!leftIcon || !!rightIcon,
          error,
          ...field,
        }}
      />
    )}
  </FieldWrapper>
);

export default InputField;
