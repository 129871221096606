import { FC } from "react";

import Modal from "../../atoms/Modal";
import { Box, Flex } from "reflexbox";
import MainButton from "../../atoms/MainButton";
import { useTranslation } from "react-i18next";

const SubmitModal: FC<any> = ({
  isOpen,
  onCancel,
  onSubmit,
  header,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} height={"300px"}>
      <Box width={"400px"}>
        <Box p={"20px"} textAlign={"center"}>
          <h3>{header}</h3>
        </Box>
        <Box p={"10px"} textAlign={"center"} color={"grey"}>
          {description}
        </Box>
        <Flex p={"10px"} pt={"40px"} justifyContent={"space-between"}>
          <MainButton onClick={onCancel}>{t("commons.cancel")}</MainButton>
          <MainButton onClick={onSubmit}>{t("commons.submit")}</MainButton>
        </Flex>
      </Box>
    </Modal>
  );
};

export default SubmitModal;
