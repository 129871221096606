import apisauce from "apisauce";
import { SERVER_BASE_URL, SERVER_LAX_URL } from "../config";

export const apiBase = apisauce.create({
  baseURL: SERVER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30 * 1000,
});

apiBase.addAsyncRequestTransform((request) => async () => {
  const token = localStorage.getItem("token");
  if (token != null) request.headers["Access-token"] = token;
});

export const apiLax = apisauce.create({
  baseURL: SERVER_LAX_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30 * 1000,
});

export const fetchShops = () => apiLax.get(`/shops`);

export const fetchProductOptions = (productId: string) =>
  apiLax.get(`/productOptions?productId=${productId}`);

export const fetchShopAssortment = ({ query }: any) =>
  apiLax.get(`/sortiment`, { ...query });

export const fetchProductList = () => apiLax.get(`/productList`);

export const fetchPrice = ({ queryParams }: any) =>
  apiLax.post(`/price${queryParams}`);

export const fetchCart = () => apiBase.get(`/cart`);

export const postAddToCart = ({ query }: any) =>
  apiBase.post(`/cart/item/add`, { ...query });

export const postDeleteCartItem = ({ query }: any) =>
  apiBase.post(`/cart/item/delete`, { ...query });

export const postReserveCartItems = ({ cartId, lang }: any) =>
  apiBase.post(`/cart/reserve/${cartId}?lang=${lang}`);

export const getMembers = () => apiBase.get(`cart/member`);

export const addMembers = ({ query }: any) =>
  apiBase.post(`cart/member`, { ...query });

export const deleteMember = ({ memberId }: any) =>
  apiBase.delete(`/members/${memberId}`);

export const getUserInfo = () => apiBase.get(`user/info`);
