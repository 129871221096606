import { FC, useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";

const AuthenticatedWrapper: FC = ({ children }) => {
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;

  useEffect(() => {
    if (!token) window.location.href = window.location.origin;
  }, [token]);

  return <>{token ? children : null} </>;
};

export default AuthenticatedWrapper;
