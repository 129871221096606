import { FC, HTMLAttributes } from "react";
import "./styled.css";

export interface IButton extends HTMLAttributes<HTMLButtonElement> {
  children: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const MainButton: FC<IButton> = ({
  children,
  className,
  disabled,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={`${className || "submit-button"} ${
        disabled ? "submit-button-disabled" : ""
      }`}
      type={"button"}
    >
      {children}
    </button>
  );
};

export default MainButton;
