import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import BuyItem from "./components/pages/BuyItem";
import Cart from "./components/pages/Cart";
import AuthenticatedWrapper from "./components/templates/AuthenticatedWrapper";
// import Reserve from "./components/pages/Reserve";

const MainContainerWrapper: FC = ({ children }) => (
  <div className="wrapper-main-container">
    <div className="main-container">{children}</div>
  </div>
);

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainContainerWrapper>
            <BuyItem />
          </MainContainerWrapper>
        }
      />
      <Route
        path="/cart"
        element={
          <MainContainerWrapper>
            <AuthenticatedWrapper>
              <Cart />
            </AuthenticatedWrapper>
          </MainContainerWrapper>
        }
      />
      {/*<Route*/}
      {/*  path="/reservation"*/}
      {/*  element={*/}
      {/*    <MainContainerWrapper>*/}
      {/*      <AuthenticatedWrapper>*/}
      {/*        <Reserve />*/}
      {/*      </AuthenticatedWrapper>*/}
      {/*    </MainContainerWrapper>*/}
      {/*  }*/}
      {/*/>*/}
    </Routes>
  );
};

export default AppRouter;
