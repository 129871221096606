import { FC, useContext, useEffect, useState } from "react";
import {
  fetchCart,
  postDeleteCartItem,
  postReserveCartItems,
} from "../../../api/endpoints";
import MainButton from "../../atoms/MainButton";
import BookTicketStatusBar from "../../molecules/BookTicketStatusBar";
import Price from "../../atoms/Pice";
import { toast } from "react-toastify";
import CartItem from "../../molecules/CartItem";
import { FaArrowLeft } from "react-icons/fa";
import { Box, Flex } from "reflexbox";
import { AppContext } from "../../../AppContext";
import { useTranslation } from "react-i18next";
import CartWrapper from "./CartWrapper";
import i18next from "i18next";
import {
  BackContainer,
  ButtonContainer,
  InfoContainer,
  PriceAndButtons,
  PriceContainer,
} from "./styled";

const BuyItem: FC = () => {
  const { t } = useTranslation();
  const cartId = sessionStorage.getItem("cartId");
  const [cart, setCart] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;
  const backToShopHref = i18next.language === "en" ? "/?lang=en" : "/";

  const getCart = () => {
    setLoading(true);
    fetchCart().then((res: any) => {
      res.ok && setCart(res?.data);
      setLoading(false);
    });
  };

  const onDeleteCartItemClick = (cartItemId: string) => {
    if (cartItemId && cartId)
      postDeleteCartItem({
        query: { cartId, cartItemId },
      }).then((res) => {
        if (res.ok) {
          getCart();
          toast(t("cart.itemDeleted"), { type: "success" });
        } else toast(t("cart.itemNoDeleted"), { type: "error" });
      });
  };
  const onReserveClick = () => {
    if (cartId)
      postReserveCartItems({ cartId, lang: i18next.language }).then((res) => {
        if (res.ok) {
          setCart({});
          sessionStorage.setItem("cartId", "");
          toast(t("cart.itemReserved"), { type: "success" });
        } else toast(t("cart.itemNoReserved"), { type: "error" });
      });
  };

  useEffect(() => {
    if (cartId) getCart();
  }, [cartId]);

  return (
    <CartWrapper>
      {(product: any) => (
        <div>
          <Box pb={"10px"}>
            <BookTicketStatusBar title={t("cart.cart")} />
          </Box>
          <a
            href={backToShopHref}
            style={{ textDecoration: "none", color: "black" }}
          >
            <BackContainer>
              <FaArrowLeft />
              <h4 style={{ margin: 0, paddingLeft: "20px" }}>
                {t("cart.backToShop")}
              </h4>
            </BackContainer>
          </a>
          <Flex flexWrap={"wrap"} mb={"20px"} alignItems={"flex-start"}>
            <Box width={[1, 1, 1, "72%"]} mr={[0, 0, 0, "2%"]}>
              {loading ? (
                <InfoContainer>
                  <h4>{"..." + t("commons.loading")}</h4>
                </InfoContainer>
              ) : cart?.cartItems ? (
                cart.cartItems
                  .filter((item: any) => !item.reserved)
                  .map((item: any, index: number) => (
                    <CartItem
                      key={item?.id || `cartItem${index}`}
                      {...item}
                      onDeleteClick={() => onDeleteCartItemClick(item?.id)}
                      productPersonalisation={product?.personalisation}
                    />
                  ))
              ) : (
                <InfoContainer>
                  <h4>{t("cart.emptyCart")}</h4>
                </InfoContainer>
              )}
            </Box>
            <PriceContainer>
              <h3
                style={{
                  width: "100%",
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: "18px",
                }}
              >
                {t("commons.price")}
              </h3>
              <PriceAndButtons>
                <Price price={cart?.price || 0} />
                <ButtonContainer>
                  <MainButton disabled={!cart} onClick={onReserveClick}>
                    {t("cart.createReservation")}
                  </MainButton>
                </ButtonContainer>
                {/*<div*/}
                {/*  style={{*/}
                {/*    paddingTop: "20px",*/}
                {/*    width: "100%",*/}
                {/*    display: "flex",*/}
                {/*    justifyContent: "center",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <Link to={"/reservation"}>*/}
                {/*    <MainButton>Go to reservation</MainButton>*/}
                {/*  </Link>*/}
                {/*</div>*/}
              </PriceAndButtons>
            </PriceContainer>
          </Flex>
        </div>
      )}
    </CartWrapper>
  );
};

export default BuyItem;
