import moment from "moment";

interface RenderTileContentProps {
  date: Date;
  view?: string;
  today: string;
  cleanStartDate?: string;
  cleanEndDate?: string;
}

export const renderTileContent = ({
  date,
  view = "month",
  today,
  cleanStartDate,
  cleanEndDate,
}: RenderTileContentProps): JSX.Element | null => {
  const convertedDate = moment(date).format("YYYY-MM-DD");
  const currentDate = moment(date).isSameOrAfter(today);
  const dateIsStartDate =
    cleanStartDate && moment(cleanStartDate).isSame(convertedDate);
  const dateIsEndDate =
    cleanEndDate && moment(cleanEndDate).isSame(convertedDate);

  const oneDaySelect = dateIsStartDate && dateIsEndDate;
  const dateIsBetween =
    cleanStartDate &&
    cleanEndDate &&
    moment(convertedDate).isBetween(cleanStartDate, cleanEndDate);

  const containerBackground =
    dateIsStartDate || dateIsEndDate
      ? "#333"
      : dateIsBetween
      ? "#4f4f4f"
      : "#d3eadd";

  const containerFontColor =
    dateIsStartDate || dateIsEndDate || dateIsBetween ? "#FFF" : "#000";
  const priceFontColor =
    dateIsStartDate || dateIsEndDate || dateIsBetween ? "#FFF" : "#808080";
  const todayTileContent =
    view === "month" && currentDate ? (
      <div className={"dayContainer"}>
        <div
          className={"dayContent"}
          style={{
            background: containerBackground,
            color: containerFontColor,
            borderTopLeftRadius:
              (dateIsEndDate && !oneDaySelect) || dateIsBetween ? "0" : "6px",
            borderTopRightRadius:
              (dateIsStartDate && !oneDaySelect) || dateIsBetween ? "0" : "6px",
            borderBottomRightRadius:
              (dateIsStartDate && !oneDaySelect) || dateIsBetween ? "0" : "6px",
            borderBottomLeftRadius:
              (dateIsEndDate && !oneDaySelect) || dateIsBetween ? "0" : "6px",
          }}
        >
          <div>{date.getDate()}</div>
        </div>
      </div>
    ) : null;

  return todayTileContent;
};
