const formatPriceForDisplay = (priceToFormat: number) => {
  const formattedPrice = String(
    priceToFormat === 0
      ? "0.-"
      : priceToFormat
      ? addApostropheForThousand(Number(priceToFormat * 100).toFixed(0))
      : "-"
  );
  return formattedPrice;
};

export const addApostropheForThousand = (number: any) => {
  const numberToString = number.toString();
  const numberAfterComma =
    numberToString.substring(numberToString.length - 2) &&
    numberToString.substring(numberToString.length - 2) !== "00"
      ? numberToString.substring(numberToString.length - 2)
      : "-";
  const numberWithDotsAfter2LastChars =
    numberToString.substring(0, numberToString.length - 2) +
    "." +
    numberAfterComma;
  return numberWithDotsAfter2LastChars.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

export default formatPriceForDisplay;
