import IndicatorsContainer from './IndicatorsContainer';
import SingleValue from './SingleValue';
import MultiValue from './MultiValue';
import Option from './Option';

export default {
  IndicatorSeparator: () => null,
  IndicatorsContainer,
  SingleValue,
  MultiValue,
  Option,
};
