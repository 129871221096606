import { HTMLAttributes } from "react";
import styled from "@emotion/styled";
import { Box } from "reflexbox";

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  readonly type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  includeIcon?: boolean;
  error?: boolean;
  disabled?: boolean;
  withoutBorder?: boolean;
  textAlign?: "left" | "right" | "center";
}

const Input = styled(Box)<InputProps>`
  outline: none;
  position: relative;
  border: none;
  background-color: rgb(240, 240, 240);
  color: black;
  padding: ${({ includeIcon }) =>
    `14px 0 14px ${includeIcon ? "56px" : "10px"}`};
  height: 48px;
  ${({ textAlign }) => textAlign && `text-align:${textAlign};`}
  ::placeholder {
    color: #677094;
    opacity: 1;
  }

  ${({ disabled }) => disabled && `opacity: 0.5;`}
  ${({ withoutBorder }) =>
    withoutBorder &&
    `border:none;
     box-shadow:none; 
     &:focus {
    border: none;
    box-shadow:none;
  }`}

  &[type='number']::-webkit-inner-spin-button,
  &input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

Input.defaultProps = {
  as: "input",
  width: 1,
  fontSize: "18px",
  bg: "backgroundPrimary",
};

export default Input;
