import { FC, useContext } from "react";
import { Exit, Container } from "./styled";
import Modal from "../../atoms/Modal";
import { Flex } from "reflexbox";
import { Form, Formik } from "formik";
import InputField from "../../molecules/InputField";
import DateSelectField from "../../molecules/DateSelectField";
import MainButton from "../../atoms/MainButton";
import { addMembers } from "../../../api/endpoints";
import { validationSchema } from "./validationSchema";
import { toast } from "react-toastify";
import { AppContext } from "../../../AppContext";
import { useTranslation } from "react-i18next";

const AddMemberForm: FC<any> = ({ isOpen, setIsOpen, fetchMembers }) => {
  const { t } = useTranslation();
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;

  const onSubmit = (data: any) => {
    addMembers({ query: { ...data, token } }).then((res) => {
      if (res.ok) {
        toast(t("commons.newMemberAdded"), { type: "success" });
        fetchMembers();
        setIsOpen(false);
      } else toast(t("commons.newMemberNotAdded"), { type: "error" });
    });
  };
  return (
    <Modal isOpen={isOpen}>
      <Container>
        <h3
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "18px",
          }}
        >
          {t("buyItem.addPerson")}
        </h3>
        <Exit onClick={() => setIsOpen(false)}> + </Exit>
        <Formik
          initialValues={{ firstname: "", lastname: "", birthday: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form>
              <InputField label={t("commons.firstname")} name={"firstname"} />
              <InputField label={t("commons.lastName")} name={"lastname"} />
              <DateSelectField
                label={t("commons.birthDate")}
                name={"birthday"}
              />
              <Flex justifyContent={"center"} p={"20px 0"}>
                <MainButton onClick={() => handleSubmit()}>
                  {t("commons.add")}
                </MainButton>
              </Flex>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default AddMemberForm;
