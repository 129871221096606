import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRouter from "./AppRouter";
import "react-toastify/dist/ReactToastify.css";
import { AppProvider } from "./AppContext";

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <AppRouter />
        <ToastContainer />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
