import styled from "@emotion/styled";
import { Flex, Box } from "reflexbox";

export const Container = styled(Box)`
  position: relative;
  padding: 10px;
  width: 330px;
`;

export const MemberContainer = styled(Flex)`
  padding: 8px 0;
  border-bottom: 2px solid rgb(240, 240, 240);
  justify-content: space-between;
  align-items: center;
`;
export const Name = styled(Box)`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 4px;
`;
export const BirthDay = styled(Box)`
  font-size: 16px;
  color: grey;
`;
export const Error = styled(Box)`
  font-size: 16px;
  color: red;
`;
export const Exit = styled(Box)`
  position: absolute;
  transform: rotate(45deg);
  font-size: 40px;
  top: 0px;
  right: 14px;
  cursor: pointer;
  color: red;
`;

export const ArrowContainer = styled(Box)`
  position: absolute;
  top: -30px;
  right: 0;
`;
