import React, { FC } from "react";
import FieldWrapper from "../FieldWrapper";
import DateSelect from "../DateSelect";

interface SelectFieldProps {
  label?: string;
  name: string;
  wrapperStyle?: object;
  info?: string;
  required?: boolean;
  tooltipMessage?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

// todo: fix select doesnt display initial value
const DateSelectField: FC<SelectFieldProps> = ({
  label,
  name,
  wrapperStyle,
  info,
  required = false,
  tooltipMessage,
  disabled,
}) => (
  <FieldWrapper
    {...{ label, name, wrapperStyle, info, required, tooltipMessage }}
  >
    {(field, { setValue }) => (
      <DateSelect setDate={setValue} value={field.value} disabled={disabled} />
    )}
  </FieldWrapper>
);

export default DateSelectField;
