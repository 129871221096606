import moment from "moment";
import { FC } from "react";
import BookingCalendar from "../BookingCalendar/BookingCalendar";
import "./styled.css";
import { renderTileContent } from "./functions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Index: FC<any> = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const { t } = useTranslation();
  const today = moment(new Date()).format("YYYY-MM-DD");

  const onChangeBookingCalendar = (date: Date): void => {
    const momentDate = moment(date);
    const dateIsEndDate = momentDate.isSame(endDate);
    const dateIsBeforeStartDate = momentDate.isBefore(startDate);
    const newDate = moment(date).format("YYYY-MM-DD");
    if (dateIsEndDate || dateIsBeforeStartDate || !startDate) {
      setStartDate(newDate);
      setEndDate(newDate);
    } else {
      setEndDate(newDate);
    }
  };
  const getCalendarValue = () =>
    startDate
      ? endDate
        ? startDate === endDate
          ? new Date(startDate)
          : [new Date(startDate), new Date(endDate)]
        : new Date(startDate)
      : undefined;

  return (
    <div>
      <h3
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "18px",
        }}
      >
        {t("commons.date")}
      </h3>
      <BookingCalendar
        lang={i18next.language}
        onChange={onChangeBookingCalendar}
        value={getCalendarValue()}
        renderTileContent={(props) =>
          renderTileContent({
            ...props,
            today,
            cleanStartDate: startDate,
            cleanEndDate: endDate,
          })
        }
      />
    </div>
  );
};

export default Index;
