import React, { FC, useState } from "react";
import { Box, Flex } from "reflexbox";
import {
  MembersContainer,
  MemberContainer,
  Name,
  GreyText,
  Square,
  ArrowContainer,
  Error,
  MembersList,
  Delete,
} from "./styled";
import { FaAngleRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import AddMemberForm from "../../../../organisms/AddMemberForm";
import addPerson from "../../../../../icons/user-plus.svg";
import { useTranslation } from "react-i18next";
import { deleteMember } from "../../../../../api/endpoints";
import SubmitModal from "../../../../organisms/SubmitModal";
import { MemberListProps } from "./types";
import { Member } from "../types";

const MemberList: FC<MemberListProps> = ({
  members,
  selectedMember,
  setSelectedMember,
  setFieldValue,
  goNext,
  personalisation,
  errors,
  submitCount,
  fetchMembers,
  loadingMembers,
}) => {
  const { t } = useTranslation();
  const [addModal, setAddModal] = useState<boolean>(false);
  const [deletedMember, setDeletedMember] = useState<Member | undefined>(
    undefined
  );

  const showError =
    Object.keys(errors).some((errorKey) =>
      Object.keys(personalisation).includes(errorKey)
    ) && submitCount > 0;

  const changeMember = (member: Member) => {
    const userPersonalisation = member?.json ? JSON.parse(member.json) : {};
    setSelectedMember(member);
    Object.keys(personalisation).map((key) => {
      if (Object.keys(userPersonalisation).includes(key))
        setFieldValue(key, userPersonalisation[key]);
      else if (Object.keys(member).includes(key)) {
        //@ts-ignore
        setFieldValue(key, member[key]);
      } else setFieldValue(key, "");
    });
    setFieldValue("birthdate", member?.birthday || "");
    goNext();
  };

  const removeMember = (member: Member) => {
    deleteMember({ memberId: member.id }).then((res: any) => {
      if (res.ok) {
        fetchMembers();
        setSelectedMember(undefined);
        Object.keys(personalisation).map((key) => {
          setFieldValue(key, "");
        });
        setDeletedMember(undefined);
      }
    });
  };
  return (
    <MembersContainer>
      {selectedMember && (
        <ArrowContainer>
          <FaAngleRight size={26} onClick={goNext} />
        </ArrowContainer>
      )}
      <h3
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: "18px",
        }}
      >
        {t("buyItem.selectMember")}
      </h3>
      {showError && (
        <Error>
          {selectedMember
            ? t("buyItem.checkPersonalSettings")
            : t("buyItem.noMemberSelected")}
        </Error>
      )}
      <MembersList>
        {!loadingMembers ? (
          <>
            {members?.length > 0 ? (
              members.map((member: any, index: number) => (
                <MemberContainer
                  key={member?.id ? member?.id : `member${index}`}
                >
                  <Box>
                    <Name>
                      {(member?.firstname || "firstname") +
                        " " +
                        (member?.lastname || "lastname")}
                    </Name>
                    <GreyText>{member?.birthday}</GreyText>
                  </Box>
                  <Flex alignItems={"center"}>
                    <Square onClick={() => changeMember(member)}>
                      {selectedMember?.id === member?.id && (
                        <FaCheck size={16} />
                      )}
                    </Square>
                    <Delete onClick={() => setDeletedMember(member)}>+</Delete>
                  </Flex>
                </MemberContainer>
              ))
            ) : (
              <Flex justifyContent={"center"} p={"60px 0"}>
                {t("buyItem.noMembers")}
              </Flex>
            )}
          </>
        ) : (
          <Flex justifyContent={"center"} p={"60px 0"}>
            {"..." + t("commons.loading")}
          </Flex>
        )}
      </MembersList>
      <Flex
        onClick={() => setAddModal(true)}
        mt={"10px"}
        style={{ cursor: "pointer" }}
      >
        <img src={addPerson} alt="add person icon" />
        <GreyText pl={"10px"}> {t("buyItem.addPerson")}</GreyText>
      </Flex>
      <AddMemberForm
        isOpen={addModal}
        setIsOpen={setAddModal}
        fetchMembers={fetchMembers}
      />

      <SubmitModal
        isOpen={Boolean(deletedMember)}
        onCancel={() => setDeletedMember(undefined)}
        header={t("buyItem.deleteMember")}
        description={
          t("buyItem.descriptionRemove") +
          ` (${deletedMember?.firstname + " " + deletedMember?.lastname})`
        }
        onSubmit={() => deletedMember && removeMember(deletedMember)}
      />
    </MembersContainer>
  );
};

export default MemberList;
