import styled from "@emotion/styled";
import { Box } from "reflexbox";

export const Container = styled(Box)`
  width: 100%;
  position: relative;
`;

export const ArrowContainer = styled(Box)`
  position: absolute;
  top: 16px;
  left: 0;
  cursor: pointer;
`;
