const getDayOption = (month: string, year: string) =>
  new Array(
    month && year ? new Date(Number(year), Number(month) + 1, 0).getDate() : 31
  )
    .fill(0)
    .map((value, index) => ({
      label: String(index + 1),
      value: String(index + 1),
    }));

const monthOptions = new Array(12).fill(0).map((value, index) => ({
  label: String(index + 1),
  value: String(index),
}));

const yearOptions = new Array(new Date().getFullYear() - 1850)
  .fill(0)
  .map((value, index) => ({
    label: String(index + 1851),
    value: String(index + 1851),
  }))
  .reverse();

export { getDayOption, monthOptions, yearOptions };
